import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="link-interceptor"
export default class extends Controller {
  static values = { url: String };

  visitLink(event) {
    event.preventDefault();
    const url = this.urlValue;
    window.location = url;
  }
}
