import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

export default class extends Controller {
  static values = { url: String };
  static targets = ["select"];

  handleChange() {
    const url = this.urlValue;
    const value = this.selectTarget.value;

    put(url, {
      body: JSON.stringify({
        profile: {
          font: value,
        },
      }),
      responseKind: "turbo-stream",
    });
  }
}
