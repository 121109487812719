import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="custom"
export default class extends Controller {
  static values = { url: String };
  static targets = ["bg-style", "color-from", "color-to"];

  connect() {}

  submit() {
    const url = this.urlValue;

    put(url, {
      body: JSON.stringify({
        profile: {
          config: {
            bgStyle: this.bgStyleTarget.dataset.value,
          },
        },
      }),
      responseKind: "turbo-stream",
    });
  }
}
