import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="image"
export default class extends Controller {
  static targets = ["filepicker", "image", "placeholder", "removeimage"];

  imageSelected(event) {
    this.placeholderTarget.classList.add("hidden");
    this.removeimageTarget.classList.remove("hidden");
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageTarget.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  removeImage(event) {
    event.preventDefault();

    this.placeholderTarget.classList.remove("hidden");
    this.removeimageTarget.classList.add("hidden");
    this.imageTarget.src = "";
    this.filepickerTarget.value = "";
  }

  pickImage(event) {
    this.filepickerTarget.click();
  }
}
