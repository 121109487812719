import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

export default class extends Controller {
  static targets = ["option"];
  static values = { active: String, inactive: String };

  select(event) {
    event.stopPropagation();
    const target = event.currentTarget;
    this.optionTargets.forEach((element) => {
      this.activeValue.split(" ").forEach((value) => {
        element.classList.remove(value);
      });
    });

    this.activeValue.split(" ").forEach((value) => {
      target.classList.add(value);
    });
  }
}
