import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="background-color"
export default class extends Controller {
  static values = { field: String };

  connect() {}

  save(event) {
    const config = {};
    config[event.detail.field] = event.detail.color;

    put("/appearance", {
      body: JSON.stringify({
        profile: {
          config: config,
        },
      }),
      responseKind: "turbo-stream",
    });
  }
}
