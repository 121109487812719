import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  close() {
    this.element.remove();
    this.modalTurboFrame.src = null;
  }

  escClose(event) {
    if (event.key == "Escape") this.close();
  }

  get modalTurboFrame() {
    return this.element.querySelector("turbo-frame[id='modal']");
  }
}
