import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

// Connects to data-controller="knob"
export default class extends Controller {
  static values = { url: String };

  checked(event) {
    const target = event.target;
    const id = target.dataset.linkId;
    const url = this.urlValue.replace(":id", id);

    put(url, {
      body: JSON.stringify({ link: { enabled: target.checked } }),
      responseKind: "turbo-stream",
    });
  }
}
