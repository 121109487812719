import { application } from "./application";

import { Dropdown } from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);

import { Alert } from "tailwindcss-stimulus-components";
application.register("alert", Alert);

import { Toggle } from "tailwindcss-stimulus-components";
application.register("alert", Toggle);

import Clipboard from "stimulus-clipboard";
application.register("clipboard", Clipboard);

import Flatpickr from "stimulus-flatpickr";
application.register("flatpickr", Flatpickr);
